import React, {
    useMemo
} from "react";
import {
    Link
} from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";

import defaultBanner from "../../img/default-event-banner.jpg";

function EventCard({ event }) {
    const bannerUrl = useMemo(() => {
        if(!event) {
            return null;
        }
        if(!event.banner) {
            return defaultBanner;
        }
        return event.banner.alternatives?.banner_card_1080x540?.url ?? event.banner.url;
    }, [event]);
    const logoUrl = useMemo(() => {
        if(!event?.logo) {
            return null;
        }
        return event.logo.alternatives?.logo_1080_width?.url ?? event.logo.url;
    }, [event]);

    return (
        <Link
            to={`/event/${event.id}`}
            className="card-event-link"
        >
            <div
                className={ "card-event w-100" + (event.live ? " live" : "") }
                style={{
                    backgroundImage: `url(${bannerUrl})`,
                }}
            >
                { event.live ? (
                    <div className="live-badge">
                        LIVE
                    </div>
                ) : event.startDate && (
                    <span className="date-badge">
                        {moment(event.startDate).format("DD-MM-YYYY")}
                    </span>   
                )}
                { logoUrl ? (
                    <div
                        className="event-logo"
                        style={{
                            backgroundImage: `url(${logoUrl})`,
                        }}
                    />
                ) : (
                    <div className="flex-grow-1"/>
                )}
                <div className="event-title-container">
                    <h6 className="event-title">
                        { event.name }
                    </h6>
                </div>
            </div>
        </Link>
    );
}

EventCard.propTypes = {
    event: PropTypes.object.isRequired,
};

export default React.memo(EventCard);

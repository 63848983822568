import React, {
    useState,
    useCallback,
    useEffect
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";
import PropTypes from "prop-types";

import UserVehicleForm from "./UserVehicleForm";

function UserVehicleEditModal({ userVehicle, show, handleClose, onVehicleUpdated }) {
    const [editedUserVehicle, setEditedUserVehicle] = useState(null);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if(!show || !userVehicle) {
            return;
        }
        setEditedUserVehicle(userVehicle);
        setSaving(false);
        setError(null);
    }, [show, userVehicle]);

    const onSave = useCallback(async () => {
        setError(null);
        setSaving(true);
        try {
            const response = await axios.post("/setUserVehicle", {
                ...editedUserVehicle,
                userVehicleId: userVehicle.id,
            });
            onVehicleUpdated(response.data.userVehicle);
            handleClose();
        } catch(requestError) {
            console.error(requestError);
            setError("Er is iets fout gegaan. Probeer het later opnieuw.");
            setSaving(false);
        }
    }, [userVehicle, editedUserVehicle, onVehicleUpdated, handleClose]);

    return (
        <Modal
            className="modal-dark"
            show={ show }
            onHide={ handleClose }
        >
            <Modal.Header closeButton>
                <Modal.Title>Voertuig bewerken</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { error && (
                    <Alert variant="danger">{ error }</Alert>
                )}
                <UserVehicleForm
                    userVehicle={ editedUserVehicle }
                    setUserVehicle={ setEditedUserVehicle }
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={ handleClose }
                    disabled={ saving }
                >
                    Annuleren
                </Button>
                <Button
                    variant="primary"
                    onClick={ onSave }
                    disabled={ saving }
                >
                    { saving && (
                        <Spinner animation="border" size="sm" className="mr-2"/>
                    )}
                    Opslaan
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
UserVehicleEditModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    userVehicle: PropTypes.object,
    onVehicleUpdated: PropTypes.func.isRequired,
};

export default React.memo(UserVehicleEditModal);

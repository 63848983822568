import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import PropTypes from "prop-types";

import Loading from "../../Loading";
import UserVehicle from "./UserVehicle";
import UserVehicleAddModal from "./UserVehicleAddModal";

function UserVehicles({ user }) {
    const [vehicles, setVehicles] = useState(null);
    const [error, setError] = useState(null);

    const [showNewVehicleModal, setShowNewVehicleModal] = useState(false);

    const refreshVehicles = useCallback(async (requestUser) => {
        try {
            const response = await axios.post("/getUserVehicle", { userId: requestUser.id });
            setVehicles(response.data.userVehicles);
        } catch(requestError) {
            console.error(requestError);
            setError("Er is iets fout gegaan. Probeer het later opnieuw.");
        }
    }, []);

    useEffect(() => {
        refreshVehicles(user);
    }, [refreshVehicles, user]);

    const onUserVehicleUpdated = useCallback(async (updatedUserVehicle) => {
        if(!updatedUserVehicle) {
            await refreshVehicles(user);
            return;
        }
        setVehicles((previousVehicles) => previousVehicles.map((vehicle) => (
            vehicle.id === updatedUserVehicle.id ? updatedUserVehicle : vehicle
        )));
    }, [refreshVehicles, user]);
    const onUserVehicleRemoved = useCallback((removedUserVehicle) => {
        setVehicles((previousVehicles) => previousVehicles.filter((vehicle) => vehicle.id !== removedUserVehicle.id));
    }, []);

    return (
        <div className="card mb-3">
            <UserVehicleAddModal
                show={ showNewVehicleModal }
                handleClose={ () => setShowNewVehicleModal(false) }
                onVehicleAdded={ () => refreshVehicles(user) }
            />
            <div className="card-body">
                <div className="d-flex flex-row">
                    <div className="flex-grow-1">
                        <h5 className="card-title">
                            Voertuigen
                        </h5>
                    </div>
                    <div>
                        <OverlayTrigger overlay={(props) => (
                            <Tooltip {...props}>
                                Voertuig toevoegen
                            </Tooltip>
                        )}>
                            <Button
                                variant="primary"
                                size="sm"
                                onClick={ () => setShowNewVehicleModal(true) }
                            >
                                <i className="fa-solid fa-plus fa-fw"/>
                            </Button>
                        </OverlayTrigger>
                    </div>
                </div>
                { error ? (
                    <Alert variant="danger">{ error }</Alert>
                ) : !vehicles ? (
                    <Loading/>
                ) : vehicles.length === 0 ? (
                    <div className="text-center">
                        <div className="h1">
                            <i className="fa-solid fa-steering-wheel"/>
                        </div>
                        <p className="card-text h5">
                            Geen voertuigen toegevoegd
                        </p>
                        <p className="card-text">
                            Voeg je voertuig(en) toe om je profiel compleet te maken.
                        </p>
                        <Button
                            variant="primary"
                            onClick={ () => setShowNewVehicleModal(true) }
                        >
                            <i className="fa-solid fa-plus mr-2"/>
                            Voertuig toevoegen
                        </Button>
                    </div>
                ) : vehicles.map((vehicle) => (
                    <UserVehicle
                        key={ vehicle.id }
                        userVehicle={ vehicle }
                        onVehicleUpdated={ onUserVehicleUpdated }
                        onVehicleRemoved={ onUserVehicleRemoved }
                    />
                ))}
            </div>
        </div>
    );
}
UserVehicles.propTypes = {
    user: PropTypes.object.isRequired
};

export default React.memo(UserVehicles);

import React, {
    useMemo
} from "react";
import {
    Button,
    Modal
} from "react-bootstrap";
import PropTypes from "prop-types";

import useWindowSize from "../../utilities/useWindowSize";

function ImageModal({ image, show, handleClose }) {
    const size = useWindowSize();

    const onMouseClickBackground = useMemo(() => {
        return (event) => {
            if(event.target.nodeName !== "IMG") {
                handleClose();
            }
        };
    }, [handleClose]);

    return (
        <React.Fragment>
            <Modal size="image" show={ show } onHide={ handleClose }>
                <Modal.Body className="text-center">
                    <div className="image-modal-container">
                        <div className="image-modal-image" onClick={ onMouseClickBackground }>
                            <Button className="modal-image-close" variant="link" size="lg" onClick={ handleClose }>
                                <i className="fas fa-times"/>
                            </Button>
                            { image && (
                                <img
                                    src={ image.url }
                                    alt="Selected"
                                    style={{
                                        maxWidth: "100%",
                                        maxHeight: size.height
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex w-100">
                        <div className="flex-grow-1"/>
                        <div>
                            <Button variant="secondary" onClick={ handleClose } className="ml-2">
                                Sluiten
                            </Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
}
ImageModal.propTypes = {
    image: PropTypes.object,
    show: PropTypes.bool,
    handleClose: PropTypes.func
};

export default React.memo(ImageModal);

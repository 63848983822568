import React from "react";
import {
    Link
} from "react-router-dom";

import Helmet from "../../components/Helmet";
import Logo from "../../components/Logo";
import AppStoreLink from "../../components/AppStoreLink";

function Home() {
    return (
        <React.Fragment>
            <Helmet
                title="Home"
            />
            <div className="background-login">
                <div className="d-flex flex-column align-items-center w-100">
                    <Logo/>
                    <div className="card mt-3" style={{ width: "100%", maxWidth: "500px" }}>
                        <div className="card-body">
                            <h1 className="text-center mb-3">
                                Welkom bij DRVR
                            </h1>
                            <div className="d-flex flex-row w-100">
                                <Link to="/login" className="btn btn-primary mr-2 flex-grow-1">
                                    Inloggen
                                </Link>
                                <Link to="/register" className="btn btn-secondary ml-2 flex-grow-1">
                                    Registreren
                                </Link>
                            </div>
                        </div>
                    </div>
                    <AppStoreLink/>
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(Home);

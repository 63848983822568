import React, {
    useState,
    useCallback,
    useEffect
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";
import moment from "moment";
import PropTypes from "prop-types";

import UserVehicleForm from "./UserVehicleForm";

function UserVehicleAddModal({ show, handleClose, onVehicleAdded }) {
    const [userVehicle, setUserVehicle] = useState(null);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if(!show) {
            return;
        }
        setUserVehicle({
            manufacturer: "",
            model: "",
            buildYear: moment().year(),
            licensePlate: "",
        });
        setSaving(false);
        setError(null);
    }, [show]);

    const onSave = useCallback(async () => {
        setError(null);
        setSaving(true);
        try {
            const response = await axios.post("/addUserVehicle", {
                ...userVehicle,
            });
            onVehicleAdded(response.data.userVehicle);
            handleClose();
        } catch(requestError) {
            console.error(requestError);
            setError("Er is iets fout gegaan. Probeer het later opnieuw.");
            setSaving(false);
        }
    }, [userVehicle, onVehicleAdded, handleClose]);

    return (
        <Modal
            className="modal-dark"
            show={ show }
            onHide={ handleClose }
        >
            <Modal.Header closeButton>
                <Modal.Title>Voertuig toevoegen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { error && (
                    <Alert variant="danger">{ error }</Alert>
                )}
                <UserVehicleForm
                    userVehicle={ userVehicle }
                    setUserVehicle={ setUserVehicle }
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={ handleClose }
                    disabled={ saving }
                >
                    Annuleren
                </Button>
                <Button
                    variant="primary"
                    onClick={ onSave }
                    disabled={ saving }
                >
                    { saving && (
                        <Spinner animation="border" size="sm" className="mr-2"/>
                    )}
                    Toevoegen
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
UserVehicleAddModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    onVehicleAdded: PropTypes.func.isRequired,
};

export default React.memo(UserVehicleAddModal);

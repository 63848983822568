import React, {
    useContext
} from "react";

import AuthenticatedUserContext from "../context/AuthenticatedUserContext";
import Helmet from "../components/Helmet";
import Logo from "../components/Logo";
import ProfileBanner from "../components/profile/ProfileBanner";
import UserVehicles from "../components/profile/vehicles/UserVehicles";
import ProfileAddressCard from "../components/profile/ProfileAddressCard";

function Account() {
    const authenticatedUser = useContext(AuthenticatedUserContext);
    return (
        <React.Fragment>
            <Helmet title="Account"/>
            <div className="background-login">
                <div className="d-flex flex-column align-items-center w-100">
                    <Logo/>
                    <div
                        className="mt-3"
                        style={{ width: "100%", maxWidth: "1000px" }}
                    >
                        <ProfileBanner user={ authenticatedUser.user }/>
                        <div className="card mb-3">
                            <div className="card-body">
                                <h5 className="card-title">Gegevens</h5>
                                <p className="card-text">
                                    <strong>Naam:</strong> {authenticatedUser.user.name}<br/>
                                    <strong>E-mail:</strong> {authenticatedUser.user.email}
                                </p>
                            </div>
                        </div>
                        <ProfileAddressCard/>
                        <UserVehicles
                            user={ authenticatedUser.user }
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(Account);

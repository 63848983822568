import React, {
    useCallback,
    useState
} from "react";
import {
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import PropTypes from "prop-types";

import UploadImageModal from "../../image/UploadImageModal";
import ImageModal from "../../image/ImageModal";
import UserVehicleEditModal from "./UserVehicleEditModal";
import UserVehicleRemoveModal from "./UserVehicleRemoveModal";

function UserVehicle({ userVehicle, onVehicleUpdated, onVehicleRemoved }) {
    const [showImageUploadModal, setShowImageUploadModal] = useState(false);

    const [showImageModal, setShowImageModal] = useState(false);
    const [imageModalImage, setImageModalImage] = useState(null);

    const [showEditModal, setShowEditModal] = useState(false);

    const [showRemoveModal, setShowRemoveModal] = useState(false);

    const onClickImage = useCallback((image) => {
        setShowImageModal(true);
        setImageModalImage(image);
    }, []);

    return (
        <div className="card mb-3">
            <UploadImageModal
                show={ showImageUploadModal }
                handleClose={ () => setShowImageUploadModal(false) }
                requestUrl="/uploadImageUserVehicle"
                requestParameters={{ userVehicleId: userVehicle.id }}
                imageUploaded={ () => onVehicleUpdated?.() }
            />
            <ImageModal
                image={ imageModalImage }
                show={ showImageModal }
                handleClose={ () => setShowImageModal(false) }
            />
            <UserVehicleEditModal
                userVehicle={ userVehicle }
                show={ showEditModal }
                handleClose={ () => setShowEditModal(false) }
                onVehicleUpdated={ onVehicleUpdated }
            />
            <UserVehicleRemoveModal
                userVehicle={ userVehicle }
                show={ showRemoveModal }
                handleClose={ () => setShowRemoveModal(false) }
                onVehicleRemoved={ onVehicleRemoved }
            />
            <div className="card-body">
                <div className="d-flex flex-row mb-1">
                    <h6 className="card-title flex-grow-1 mb-0">
                        { userVehicle.manufacturer } { userVehicle.model }
                        <span className="badge badge-secondary badge-pill ml-2">
                            { userVehicle.buildYear }
                        </span>
                        <span className="badge badge-warning badge-pill ml-2">
                            { userVehicle.licensePlate }
                        </span>
                    </h6>
                    <div className="d-flex flex-row" style={{ gap: "0.5rem" }}>
                        <OverlayTrigger overlay={(props) => (
                            <Tooltip {...props}>
                                Voertuig bewerken
                            </Tooltip>
                        )}>
                            <Button
                                variant="secondary"
                                size="sm"
                                onClick={ () => setShowEditModal(true) }
                            >
                                <i className="fa-solid fa-pen fa-fw"/>
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger overlay={(props) => (
                            <Tooltip {...props}>
                                Afbeelding toevoegen
                            </Tooltip>
                        )}>
                            <Button
                                variant="primary"
                                size="sm"
                                onClick={ () => setShowImageUploadModal(true) }
                            >
                                <i className="fa-solid fa-image fa-fw"/>
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger overlay={(props) => (
                            <Tooltip {...props}>
                                Voertuig verwijderen
                            </Tooltip>
                        )}>
                            <Button
                                variant="danger"
                                size="sm"
                                onClick={ () => setShowRemoveModal(true) }
                            >
                                <i className="fa-solid fa-trash fa-fw"/>
                            </Button>
                        </OverlayTrigger>
                    </div>
                </div>
                <div className="d-flex">
                    { userVehicle.images.map((image) => (
                        <div
                            key={ image.id }
                            style={{ width: "25%" }}
                        >
                            <img
                                src={ image.url }
                                className="card-img-top"
                                alt={ userVehicle.manufacturer + " " + userVehicle.model }
                                style={{
                                    width: "100%",
                                    height: "auto",
                                    objectFit: "cover",
                                    aspectRatio: "1/1",
                                    cursor: "pointer",
                                }}
                                onClick={ () => onClickImage(image) }
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
UserVehicle.propTypes = {
    userVehicle: PropTypes.object.isRequired,
    onVehicleUpdated: PropTypes.func,
    onVehicleRemoved: PropTypes.func,
};

export default React.memo(UserVehicle);

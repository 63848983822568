import React, {
    useCallback
} from "react";
import PropTypes from "prop-types";

function UserVehicleForm({ userVehicle, setUserVehicle }) {
    const onChange = useCallback((event) => {
        const field = event.target.id;
        const value = event.target.value;
        setUserVehicle((prevUserVehicle) => ({ ...prevUserVehicle, [field]: value }));
    }, [setUserVehicle]);

    if(!userVehicle) {
        return null;
    }

    return (
        <React.Fragment>
            <div className="form-group">
                <label htmlFor="manufacturer">Merk</label>
                <input
                    type="text"
                    className="form-control"
                    id="manufacturer"
                    value={ userVehicle.manufacturer }
                    onChange={ onChange }
                />
            </div>
            <div className="form-group">
                <label htmlFor="model">Model</label>
                <input
                    type="text"
                    className="form-control"
                    id="model"
                    value={ userVehicle.model }
                    onChange={ onChange }
                />
            </div>
            <div className="form-group">
                <label htmlFor="buildYear">Bouwjaar</label>
                <input
                    type="number"
                    className="form-control"
                    id="buildYear"
                    value={ userVehicle.buildYear }
                    onChange={ onChange }
                />
            </div>
            <div className="form-group">
                <label htmlFor="licensePlate">Kenteken</label>
                <input
                    type="text"
                    className="form-control"
                    id="licensePlate"
                    value={ userVehicle.licensePlate }
                    onChange={ onChange }
                />
            </div>
        </React.Fragment>
    );
}
UserVehicleForm.propTypes = {
    userVehicle: PropTypes.object,
    setUserVehicle: PropTypes.func.isRequired,
};

export default React.memo(UserVehicleForm);

import React, {
    useContext
} from "react";

import AuthenticatedUserContext from "../../context/AuthenticatedUserContext";

function ProfileAddressCard() {
    const { user } = useContext(AuthenticatedUserContext);

    return (
        <div className="card mb-3">
            <div className="card-body">
                <h5 className="card-title">
                    Adresgegevens
                </h5>
                <p className="card-text text-muted">
                    Deze gegevens staan opgeslagen in je DRVR account en worden gebruikt bij het aanmelden voor een evenement.
                </p>
                <p className="card-text">
                    <strong>Adres:</strong> {user.address ?? <i className="text-muted">Niet ingevuld</i>}<br/>
                    <strong>Postcode:</strong> {user.postalCode ?? <i className="text-muted">Niet ingevuld</i>}<br/>
                    <strong>Stad:</strong> {user.city ?? <i className="text-muted">Niet ingevuld</i>}<br/>
                    <strong>Land:</strong> {user.country ?? <i className="text-muted">Niet ingevuld</i>}<br/>
                </p>
            </div>
        </div>
    );
}

export default React.memo(ProfileAddressCard);

import React, {
    useContext
} from "react";
import {
    Route,
    Switch
} from "react-router-dom";

import AuthenticatedUserContext from "./context/AuthenticatedUserContext";
import useTracking from "./hooks/useTracking";
import PrivateRoute from "./components/PrivateRoute";
import Header from "./components/Header";

import Home from "./pages/initial/Home";
import LoginPage from "./pages/initial/LoginPage";
import RegisterPage from "./pages/initial/RegisterPage";
import ForgotPassword from "./pages/initial/ForgotPassword";
import Discover from "./pages/Discover";
import TabBar from "./components/navigation/TabBar";
import Account from "./pages/Account";
import EventPage from "./pages/EventPage";
import AcceptInvite from "./pages/AcceptInvite";
import InvitePaymentFinalize from "./pages/InvitePaymentFinalize";
import ResetPassword from "./pages/initial/ResetPassword";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function DRVRRouter() {
    const authenticatedUserContext = useContext(AuthenticatedUserContext);
    useTracking();

    return (
        <Switch>

            <PrivateRoute path="/" target="/discover" exact authenticated={ authenticatedUserContext.user === null }>
                <Home/>
            </PrivateRoute>
            <PrivateRoute path="/login" target="/discover" authenticated={ authenticatedUserContext.user === null }>
                <LoginPage/>
            </PrivateRoute>
            <PrivateRoute path="/register" target="/discover" authenticated={ authenticatedUserContext.user === null }>
                <RegisterPage/>
            </PrivateRoute>
            <PrivateRoute path="/forgot-password" target="/discover" authenticated={ authenticatedUserContext.user === null }>
                <ForgotPassword/>
            </PrivateRoute>

            <PrivateRoute path="/discover" target="/" authenticated={ authenticatedUserContext.user !== null }>
                <Discover/>
                <TabBar/>
            </PrivateRoute>
            <PrivateRoute path="/account" target="/" authenticated={ authenticatedUserContext.user !== null }>
                <Account/>
                <TabBar/>
            </PrivateRoute>

            <PrivateRoute
                path={["/event/:eventId/:page", "/event/:eventId"]}
                target="/"
                authenticated={ authenticatedUserContext.user !== null }
            >
                <EventPage/>
                <TabBar/>
            </PrivateRoute>

            <Route path="/accept-invite/:token">
                <AcceptInvite/>
            </Route>
            <Route path="/invite-payment/:paymentToken">
                <InvitePaymentFinalize/>
            </Route>

            <Route path="/password-reset/:token">
                <ResetPassword/>
            </Route>

            <Route path="/privacyverklaring/clean">
                <PrivacyPolicy clean/>
            </Route>
            <Route path="/privacyverklaring">
                <Header/>
                <PrivacyPolicy/>
            </Route>

            <Route path="/">
                <Header/>
                <div className="text-center">
                    <h1>404 Not Found</h1>
                    <p>Deze pagina bestaat niet</p>
                </div>
            </Route>

        </Switch>
    );
}

export default React.memo(DRVRRouter);

import React, {
    useState,
    useCallback,
    useEffect
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";
import PropTypes from "prop-types";

function UserVehicleRemoveModal({ userVehicle, show, handleClose, onVehicleRemoved }) {
    const [removing, setRemoving] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if(!show || !userVehicle) {
            return;
        }
        setRemoving(false);
        setError(null);
    }, [show, userVehicle]);

    const onRemove = useCallback(async () => {
        setError(null);
        setRemoving(true);
        try {
            const response = await axios.post("/removeUserVehicle", {
                userVehicleId: userVehicle.id,
            });
            onVehicleRemoved(response.data.userVehicle);
            handleClose();
        } catch(requestError) {
            console.error(requestError);
            setError("Er is iets fout gegaan. Probeer het later opnieuw.");
            setRemoving(false);
        }
    }, [userVehicle, onVehicleRemoved, handleClose]);

    return (
        <Modal
            className="modal-dark"
            show={ show }
            onHide={ handleClose }
        >
            <Modal.Header closeButton>
                <Modal.Title>Voertuig verwijderen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { error && (
                    <Alert variant="danger">{ error }</Alert>
                )}
                <p className="mb-0">
                    Weet je zeker dat je { userVehicle?.manufacturer } { userVehicle?.model } ({ userVehicle?.licensePlate }) wilt verwijderen? Deze actie is niet terug te draaien!
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={ handleClose }
                    disabled={ removing }
                >
                    Annuleren
                </Button>
                <Button
                    variant="danger"
                    onClick={ onRemove }
                    disabled={ removing }
                >
                    { removing && (
                        <Spinner animation="border" size="sm" className="mr-2"/>
                    )}
                    Verwijderen
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
UserVehicleRemoveModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    userVehicle: PropTypes.object,
    onVehicleRemoved: PropTypes.func.isRequired,
};

export default React.memo(UserVehicleRemoveModal);

import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal
} from "react-bootstrap";
import ImageUploading from "react-images-uploading";
import PropTypes from "prop-types";

import Loading from "../Loading";

class UploadImageModal extends React.PureComponent {
    getDefaultState() {
        return {
            error: null,
            loading: false,
            images: []
        };
    }

    constructor(props) {
        super(props);
        this.state = this.getDefaultState();
        this.onShow = this.onShow.bind(this);
        this.onImagesChange = this.imagesChange.bind(this);
        this.uploadImages = this.uploadImages.bind(this);
    }

    imagesChange(imageList, addUpdateIndex) {
        console.log(imageList, addUpdateIndex);
        this.setState({ images: imageList });
    }

    async uploadImages() {
        this.setState({ loading: true });
        for(const image of this.state.images) {
            // eslint-disable-next-line no-await-in-loop
            if(!await this.uploadImage(image)) {
                this.setState({ loading: false, error: "Er is een fout opgetreden bij het uploaden van de foto's. Niet alle foto's zijn geupload."});
                return;
            }
        }
        this.setState({ loading: false });
        this.props.imageUploaded();
        this.props.handleClose();
    }

    async uploadImage(image) {
        this.setState({ error: null });

        if(image.file === null) {
            return false;
        }

        const formData = new FormData();
        Object.entries(this.props.requestParameters).forEach(([key, value]) => {
            formData.append(key, value);
        });
        formData.append("file", image.file, image.file.name);

        try {
            let response = await axios.post(this.props.requestUrl, formData, {
                transformRequest: (data, headers) => {
                    headers.setContentType(undefined);
                    return data;
                }
            });

            return !(!response.data || !response.data.valid);
        } catch(requestError) {
            console.error(requestError);
            return false;
        }
    }

    onShow() {
        this.setState(this.getDefaultState());
    }

    render() {
        return (
            <Modal
                show={ this.props.show }
                onHide={ this.props.handleClose }
                onShow={ this.onShow }
                size="lg"
                className="modal-dark"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Foto uploaden</Modal.Title>
                </Modal.Header>
                { this.state.loading ? (
                    <Modal.Body>
                        <Loading/>
                    </Modal.Body>
                ) : (
                    <React.Fragment>
                        <Modal.Body>
                            { this.state.error && (
                                <Alert variant="danger">{ this.state.error }</Alert>
                            )}
                            <ImageUploading
                                multiple
                                value={ this.state.images }
                                onChange={ this.onImagesChange }
                                maxNumber={ 20 }
                                dataURLKey="data_url"
                                acceptType={ ["jpg", "png", "jpeg"] }
                            >
                                {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemoveAll,
                                    onImageUpdate,
                                    onImageRemove,
                                    isDragging,
                                    dragProps,
                                }) => (
                                    <div className="image-upload">
                                        <button
                                            className={ "btn btn-image-upload" + (isDragging ? " btn-image-upload-dragging" : "") }
                                            onClick={ onImageUpload }
                                            disabled={ this.state.loading }
                                            { ...dragProps }
                                        >
                                            Klik hier om een foto toe te voegen
                                        </button>
                                        { imageList.length > 0 && (
                                            <div className="mt-2">
                                                <div className="clearfix">
                                                    <button className="btn btn-secondary btn-sm float-right" onClick={ onImageRemoveAll } disabled={ this.state.loading }>
                                                        Verwijder alle
                                                    </button>
                                                </div>
                                                <div className="d-flex flex-wrap mt-2">
                                                    { imageList.map((image, index) => (
                                                        <div key={ index } className="image-item">
                                                            <button className="btn btn-sm btn-remove" onClick={ () => onImageRemove(index) } disabled={ this.state.loading }>
                                                                <i className="fas fa-fw fa-times"/>
                                                            </button>
                                                            <button className="btn btn-image" onClick={ () => onImageUpdate(index) } disabled={ this.state.loading }>
                                                                <img src={ image.data_url } alt="" width="100" />
                                                            </button>
                                                        </div>
                                                    )) }
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </ImageUploading>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={ this.props.handleClose }>
                                Annuleer
                            </Button>
                            <Button variant="primary" onClick={ this.uploadImages }>
                                Uploaden
                            </Button>
                        </Modal.Footer>
                    </React.Fragment>
                ) }
            </Modal>
        );
    }
}
UploadImageModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    requestUrl: PropTypes.string.isRequired,
    requestParameters: PropTypes.object,
    imageUploaded: PropTypes.func
};

export default UploadImageModal;

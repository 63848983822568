import React, {
    useContext,
    useMemo
} from "react";
import PropTypes from "prop-types";
import {
    Button
} from "react-bootstrap";

import AuthenticatedUserContext from "../../context/AuthenticatedUserContext";

import defaultBanner from "../../img/default-event-banner.jpg";
import useWindowSize from "../../utilities/useWindowSize";

function ProfileBanner({ user }) {
    const authenticatedUser = useContext(AuthenticatedUserContext);
    const windowSize = useWindowSize();

    const isOwnProfile = useMemo(() => {
        if(!authenticatedUser.user || !user) {
            return false;
        }
        return authenticatedUser.user.id === user.id;
    }, [authenticatedUser.user, user]);

    const profileImageSize = useMemo(() => {
        if(windowSize.width >= 992) {
            return 200;
        }
        if(windowSize.width >= 768) {
            return 150;
        }
        if(windowSize.width <= 575) {
            return 200;
        }
        return 100;
    }, [windowSize]);

    return (
        <div className="card mb-3">
            <img
                src={ defaultBanner }
                className="card-img-top"
                alt={ user.name }
                style={{
                    objectFit: "cover",
                    height: "200px"
                }}
            />
            <div className="card-body">
                <div className="d-flex flex-column flex-sm-row w-100 align-items-center">
                    <div
                        className="mr-sm-3 mb-3"
                        style={{
                            marginTop: `-${profileImageSize * 0.75}px`,
                        }}
                    >
                        <img
                            src={ user.avatar ? user.avatar.url : defaultBanner }
                            alt={ user.name }
                            className="rounded-circle"
                            style={{
                                width: `${profileImageSize}px`,
                                height: `${profileImageSize}px`,
                                objectFit: "cover"
                            }}
                        />
                    </div>
                    <div className="flex-grow-1">
                        <h4 className="card-title m-sm-0">{ user.name }</h4>
                    </div>
                    { isOwnProfile && (
                        <div>
                            <Button
                                variant="secondary"
                                onClick={ authenticatedUser.logoutFunction }
                                disabled={ authenticatedUser.loading }
                            >
                                Uitloggen
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
ProfileBanner.propTypes = {
    user: PropTypes.object.isRequired
};

export default React.memo(ProfileBanner);
